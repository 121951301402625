// @mui
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Header from "../main/Header";
import { AuthProvider } from "../../../controllers/AuthController";
import { CLIENT_ID, USER_REALM } from "../../../@types/roleModel";
//

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function CompactLayout({ children }: Props) {
  return (
    <>
      <AuthProvider realm={USER_REALM.owners} client_id={CLIENT_ID.owners}>
        <Header />
      </AuthProvider>

      <Container component={"main"}>
        <Stack
          sx={{
            py: 12,
            m: "auto",
            maxWidth: 400,
            // minHeight: "100vh",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}
